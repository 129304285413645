import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Dashboard from '../views/dashboard.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
    meta: { requiresLogin: true },
    children: [
      {
        path: '',
        name: 'Home',
        component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
        meta: { requiresLogin: true }
      },
      {
        path: 'home',
        name: 'Home',
        component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
        meta: { requiresLogin: true }
      },
      {
        path: '/categories',
        name: 'Categories',
        component: () => import(/* webpackChunkName: "categories" */ '../views/categories.vue'),
        meta: { requiresLogin: true }
      },
      {
        path: '/category/:category_id/:category_name/products',
        name: 'Products',
        component: () => import(/* webpackChunkName: "products" */ '../views/products.vue'),
        meta: { requiresLogin: true }
      },
      {
        path: '/category/:category_id/:category_name/products/:product_id/:product_name/colors',
        name: 'Colors',
        component: () => import(/* webpackChunkName: "colors" */ '../views/colors.vue'),
        meta: { requiresLogin: true }
      },
      {
        path: '/category/:category_id/:category_name/products/:product_id/:product_name/add-color',
        name: 'AddColor',
        component: () => import(/* webpackChunkName: "add-color" */ '../views/add-color.vue'),
        meta: { requiresLogin: true }
      },
      {
        path: '/category/:category_id/:category_name/products/:product_id/:product_name/edit-color/:color_id',
        name: 'EditColor',
        component: () => import(/* webpackChunkName: "add-color" */ '../views/edit-color.vue'),
        meta: { requiresLogin: true }
      },
      {
        path: '/category/:category_id/:category_name/add-product',
        name: 'AddProduct',
        component: () => import(/* webpackChunkName: "add-product" */ '../views/add-product.vue'),
        meta: { requiresLogin: true }
      },
      {
        path: '/category/:category_id/:category_name/edit-product/:product_id',
        name: 'EditProduct',
        component: () => import(/* webpackChunkName: "edit-product" */ '../views/edit-product.vue'),
        meta: { requiresLogin: true }
      },
      {
        path: '/product-groups',
        name: 'ProductGroups',
        component: () => import(/* webpackChunkName: "product-groups" */ '../views/product-groups.vue'),
        meta: { requiresLogin: true }
      },
      {
        path: '/banners',
        name: 'Banners',
        component: () => import(/* webpackChunkName: "banners" */ '../views/banners.vue'),
        meta: { requiresLogin: true }
      },
      {
        path: '/tour-types',
        name: 'TourTypes',
        component: () => import(/* webpackChunkName: "tour-types" */ '../views/tour-types.vue'),
        meta: { requiresLogin: true }
      },
      {
        path: '/:countryName/:countryID/tour-ideas',
        name: 'TourIdeas',
        component: () => import(/* webpackChunkName: "tour-ideas" */ '../views/tour-ideas.vue'),
        meta: { requiresLogin: true }
      },
      {
        path: '/:countryName/:countryID/hotels',
        name: 'Hotels',
        component: () => import(/* webpackChunkName: "hotels" */ '../views/hotels.vue'),
        meta: { requiresLogin: true }
      },
      {
        path: '/:countryName/:countryID/places-to-visit',
        name: 'PlacesToVisit',
        component: () => import(/* webpackChunkName: "places-to-visit" */ '../views/places-to-visit.vue'),
        meta: { requiresLogin: true }
      },
      {
        path: '/:countryName/:countryID/things-to-do',
        name: 'ThingsToDo',
        component: () => import(/* webpackChunkName: "things-to-do" */ '../views/things-to-do.vue'),
        meta: { requiresLogin: true }
      },
      {
        path: '/:countryName/:countryID/:tourIdeaTitle/:tourIdeaID/days',
        name: 'Days',
        component: () => import(/* webpackChunkName: "days" */ '../views/days.vue'),
        meta: { requiresLogin: true }
      },
      {
        path: '/users',
        name: 'Users',
        component: () => import(/* webpackChunkName: "users" */ '../views/Users.vue'),
        meta: { requiresLogin: true }
      },
      {
        path: '/payment',
        name: 'Payment',
        component: () => import(/* webpackChunkName: "payment" */ '../views/Payment.vue'),
      },
      {
        path: '/orders',
        name: 'Orders',
        component: () => import(/* webpackChunkName: "orders" */ '../views/orders.vue'),
        meta: { requiresLogin: true }
      },
      {
        path: '/newsletters',
        name: 'Newsletters',
        component: () => import(/* webpackChunkName: "newsletters" */ '../views/newsletters.vue'),
        meta: { requiresLogin: true }
      },
      {
        path: '/countries',
        name: 'Countries',
        component: () => import(/* webpackChunkName: "countries" */ '../views/countries.vue'),
        meta: { requiresLogin: true }
      },
      {
        path: '/:countryId/regions',
        name: 'Regions',
        component: () => import(/* webpackChunkName: "regions" */ '../views/regions.vue'),
        meta: { requiresLogin: true }
      },
      {
        path: '/:countryId/:regionId/cities',
        name: 'Cities',
        component: () => import(/* webpackChunkName: "cities" */ '../views/cities.vue'),
        meta: { requiresLogin: true }
      },
      // {
      //   path: '/draggable-items',
      //   name: 'DraggableItems',
      //   component: () => import(/* webpackChunkName: "draggable-items" */ '../views/draggable-items.vue'),
      // },

    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/login.vue')
  },

  //In case the route is anything else
  {
    path: '*',
    redirect: '/dashboard/home'
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,

  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeResolve((to, from, next) => {
  if (to.name === "Login" && localStorage.getItem('user')) {
    next({ path: "/" });
  }

  //Check if login is required for Routes with meta data loginRequired!
  if (to.matched.some(record => record.meta.requiresLogin)) {
    if (!localStorage.getItem('user')) {
      //Redirect to Login Page
      next({ path: "/login" });
    } else next();
  } else next();
});

export default router
